<template>
  <div class="container">
    <img src="/img/paladi.png" class="welcome__logo" />
    <form @submit.prevent="sendRequest()" v-if="!isConfirmCode" class="personal-form">
      <div class="personal-form__item">
        <div class="personal-form__label">
          Укажите номер мобильного телефона и мы пришлем СМС с кодом подтверждения
        </div>
        <FormText :pattern="'+7 (###) ###-##-##'" v-model="phone"/>
      </div>
      <div class="personal-form__item">
        <button class="btn personal-form__submit">
          Отправить код
        </button>
      </div>
    </form>
    <form @submit.prevent="confirmCode()" v-else class="personal-form">
      <div class="personal-form__item">
        <div class="personal-form__label">
          Введите код подтверждения
        </div>
        <FormText :pattern="'#####'" v-model="code"/>
      </div>
      <div class="personal-form__item" style="display: flex; justify-content: space-between; align-items: center;">
        <button class="btn personal-form__submit">
          Подтвердить код
        </button>
        <button @click.prevent="isConfirmCode = false" style="background: transparent; border: none; color: #ff980c;">
          Получить новый код
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import FormText from "@/components/forms/form-text";

export default {
  name: "Registration",

  components: {
    FormText,
  },

  data() {
    return {
      phone: '',
      code: null,
      isConfirmCode: false,
    }
  },

  methods: {
    sendRequest() {
      this.$store
        .dispatch("GET_SMS_CODE", {
          phone: this.phone
        })
        .then(() => {
          this.isConfirmCode = true;
        });
    },

    confirmCode() {
      this.$store
        .dispatch("CONFIRM_CODE", {
          code: this.code
        })
        .then(() => {
          this.$router.push({
            path: "/registration/registration-completed",
            replace: true,
          });
        })
    },
  }
}
</script>
